/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:32fe08f6-f6cc-4443-8cc9-d0e777dd7605",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_fC86HnWZL",
    "aws_user_pools_web_client_id": "44eeg345nh3dotlff83qiuj5vt",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://hbw6g3itnzdynfpv7lar74z3hq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wutg425qmbfp5jou76tii4nulu"
};


export default awsmobile;
